import axios from 'axios';
import { get, set, dropRight, concat } from 'lodash';
import { set as setFP } from 'lodash/fp';
import moment from 'moment';

import {
  SET_SURVEY,
  CHECK_ERROR_FP,
  SET_VALUE_GLOBAL,
  TOGGLE_BT_NEXT,
  SET_VALIDATE_SECTION,
  GET_POSTAL_CODE,
  CHECK_ERROR,
  SET_VALUE,
  SET_VALUE_DATE,
  RESET_QUESTION,
  VALIDATE_SECTION,
  FETCH_SURVEY,
  SEND_BUILDING,
  SET_ID,
  SET_CAISSE,
  RESET_VALUE,
  ADD_PREVIOUS_SECTION,
  POP_PREVIOUS_SECTION,
} from './actions';

import { getError } from '../utils/validate';
import { getPath, getProp, execQuestionDeep, execSectionDeep, execSurveyDeep, isRulesVisible } from '../utils/SurveyUtils';
import { getResponseFromServer } from '../utils/ServerParser';

const mutations = {
  [CHECK_ERROR]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    // Use set (not setFP) to not re-create the question with the new error props (don't display the new error on user input)
    set(state.survey, getPath(path, responsePath, 'error'), getError(value, question.rules));
  },
  [CHECK_ERROR_FP]: (state, { path, responsePath, value }) => {
    const question = get(state.survey, path, {});
    let survey = state.survey;
    survey = setFP(getPath(path, responsePath, 'error'), getError(value, question.rules), state.survey);
    if (getError(value, question.rules)) {
      survey = setFP('sections.risques.isValidated', false, survey);
    }
    state.survey = survey;
  },
  [ADD_PREVIOUS_SECTION]: (state, value) => {
    let survey = state.survey;
    let previousSections = get(survey, 'sections.globals.previousSection');
    survey = setFP('sections.globals.previousSection', (previousSections !== null && Array.isArray(previousSections)) ? concat(previousSections, value) : [value], survey);
    state.survey = survey;
  },
  [POP_PREVIOUS_SECTION]: (state) => {
    let survey = state.survey;
    let previousSections = get(survey, 'sections.globals.previousSection');
    survey = setFP('sections.globals.previousSection', (previousSections !== null && Array.isArray(previousSections)) ? dropRight(previousSections) : [], survey);
    state.survey = survey;
  },
  [SET_VALUE_DATE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    let valueDate = moment(value,'DD/MM/YYYY').format('DD/MM/YYYY');
    survey = setFP(getPath(path, responsePath, 'response'), valueDate, survey);
    survey = setFP(getPath(path, responsePath, 'responseFull'), value, survey);
    survey = setFP(getPath(path, responsePath, 'error'), getError(valueDate, question.rules), survey);
    state.survey = survey;
  },
  [RESET_VALUE]: (state, { path, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path), value, survey);
    state.survey = survey;
  },
  [SET_VALUE]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    survey = setFP(getPath(path, responsePath, 'response'), value, survey);
    survey = setFP(getPath(path, responsePath, 'error'), getError(value, question.rules), survey);
    const sectionPath = path.split('.');
    // on clear isBtNextClicked
    survey = setFP(getPath(`${sectionPath[0]}.${sectionPath[1]}`, 'isBtNextClicked'), false, survey);
    state.survey = survey;
  },
  [SET_VALUE_GLOBAL]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = setFP(getPath(path, responsePath), value, survey);
    state.survey = survey;
  },
  [TOGGLE_BT_NEXT]: (state, { sectionPath }) => {
    let survey = state.survey;
    survey = setFP(getPath(`sections.${sectionPath}`, 'isBtNextClicked'), !get(state.survey, getPath(`sections.${sectionPath}`, 'isBtNextClicked')), survey);
    state.survey = survey;
  },
  [SET_VALIDATE_SECTION]: (state, { path, responsePath, value }) => {
    let survey = state.survey;
    survey = set(survey, getPath(path, 'isValidated'), value);
    state.survey = survey;
  },
  [RESET_QUESTION]: (state, { path, responsePath }) => {
    let survey = state.survey;
    const question = get(state.survey, path, {});
    execQuestionDeep(question, path, responsePath, (q, p, rp) => {
      survey = setFP(getPath(p, rp, 'response'), null, survey);
      survey = setFP(getPath(p, rp, 'error'), getError(null, q.rules), survey);
    });
    state.survey = survey;
  },
  [VALIDATE_SECTION]: (state, sectionName) => {
    let survey = state.survey;
    let sectionHasError = false;
    execSectionDeep(get(state, `survey.sections.${sectionName}`), sectionName, (q, p, rp) => {
      const isVisible = isRulesVisible(q.visibilityRules, state);
      if (isVisible) {
        const error = getError(getProp(q, rp, 'response'), q.rules);
        sectionHasError = sectionHasError || error;
        survey = setFP(getPath(p, rp, 'error'), error, survey);
        return true;
      }
      return false;
    });
    survey = setFP(`sections.${sectionName}.isValidated`, !sectionHasError, survey);
    state.survey = survey;
  },
  [FETCH_SURVEY]: (state, codeClient) => {
    state.surveyWithClientCode = true;
    let currentSession = sessionStorage.getItem('parc-immobilier');
    // Si code client, on fait un appel a l'api
    // Sinon on recupère la current session si y'en a une ou on l'initialise
    if (codeClient) {
      axios.get(`${process.env.API_URL}/api/devis/${codeClient}`)
      .then(({ data }) => {
        let allSectionsValidated = true;
        let survey = state.survey;
        execSurveyDeep(survey, (s, sn) => {
          if (get(data, s.serverPathValidated) === true) {
            survey = setFP(`sections.${sn}.isValidated`, true, survey);
          } else {
            allSectionsValidated = false;
          }
        }, (q, p, rp) => {
          const serverPath = getProp(q, 'serverPath', rp);
          if (serverPath) {
            survey = setFP(
              getPath(p, rp, 'response'),
              getResponseFromServer[q.questionType](data, serverPath, q.resetServerPath),
              survey,
            );
          }
        });
        if (currentSession !== '[object Object]') {
          currentSession = JSON.parse(currentSession);
          state = currentSession;
        } else {
          state.survey = survey;
          if (allSectionsValidated) {
            state.isSurveyCompleted = true;
          }
        }
      })
      .catch(e => console.log(e));
    } else {
      if (currentSession !== '[object Object]') {
        currentSession = JSON.parse(currentSession);
        state = currentSession;
      } else {

      }
    }
  },
  [GET_POSTAL_CODE]: (state, codeClient) => {
    let survey = state.survey;
    state.surveyWithClientCode = true;
    // let currentSession = sessionStorage.getItem('parc-immobilier');
    // appler axios get, pour aller chercher les postal code
    axios.get(`${process.env.API_URL}/api/postalCode/`)
    .then(({ data }) => {
      survey.sections.globals.cpVilles = data;
      state.survey = survey;
      // puis les stocker dans le state
    })
    .catch(e => console.log(e));
  },
  [SET_SURVEY]: (state, survey) => {
    state.survey = survey;
  },
  [SEND_BUILDING]: (state, data) => {
    let survey = state.survey;
    let declaration = get(survey, `sections.globals.declarations.${data.typeDeclaration}.${data.declarationId}`);
    survey = setFP(`sections.globals.declarations.${data.typeDeclaration}.${data.response.data.data.batiment_id}`, declaration, survey);
    let declarations = get(survey, `sections.globals.declarations.${data.typeDeclaration}`);
    delete declarations[data.declarationId];
    survey = setFP(`sections.globals.declarations.${data.typeDeclaration}`, declarations, survey);
    if (data.response.data.data.devis_id !== undefined) {
      survey = setFP('sections.main.questions.exportId.response', data.response.data.data.devis_id, survey);
    }
    if (data.response.data.data.devis_caisse !== undefined && data.response.data.data.devis_caisse[0] !== undefined) {
      state.devisCaisse = setFP('caisse', data.response.data.data.devis_caisse[0], state.devisCaisse);
    }
    state.survey = survey;
  },
  [SET_ID]: (state, data) => {
    state.survey = setFP('sections.globals.questions.exportId.response', data.data.devis_id, state.survey);
  },
  [SET_CAISSE]: (state, data) => {
    state.devisCaisse = setFP('caisse', data.data.devis_caisse[0], state.devisCaisse);
  },
};

export default mutations;
