/* eslint-disable camelcase */

export const wa_gfr_init = {
  contenu: {
    fil_ariane: '',
    marche: 'particulier',
    region_enregistree: 'LBR',
    type_contenu: 'Revision Parc Immobilier',
  },
  contact: {
    origine: 'site marchand',
  },
  parc_immo: {
    ajout_bat: '',
    modification_bat: '',
    suppression_bat: '',
  },
};

if (__DEV__) {
  // implement _satellite for DEBUG purpose
  window._satellite = { track: null, pageBottom: null };
  window._satellite.track = (value) => {
    console.log('track', window.wa_gfr.contenu.fil_ariane);
  };
  window._satellite.pageBottom = (value) => { };
}

const tracking = (track, nbrAjout, nbrModif, nbrSuppr) => {
  console.log('DEBUG tracking', track, nbrAjout, nbrModif, nbrSuppr);
  switch (track) {
  case 'init': // etape1
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        ...wa_gfr_init.contenu,
        fil_ariane: 'Revision Parc Immobilier::Etape 1 Accueil',
      },
    };
    window._satellite.track('revisionparcimmo');
    window._satellite.pageBottom();
    break;
  case 'etape1.2 - Ajout':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        ...wa_gfr_init.contenu,
        fil_ariane: 'Revision Parc Immobilier::Etape 1.2 Ajout Bâtiment',
      },
    };
    window._satellite.track('revisionparcimmo');
    window._satellite.pageBottom();
    break;
  case 'etape1.2 - Modif':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        ...wa_gfr_init.contenu,
        fil_ariane: 'Revision Parc Immobilier::Etape 1.2 Modification bâtiment',
      },
    };
    window._satellite.track('revisionparcimmo');
    window._satellite.pageBottom();
    break;
  case 'etape1.2 - Suppr':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        ...wa_gfr_init.contenu,
        fil_ariane: 'Revision Parc Immobilier::Etape 1.2 Suppression bâtiment',
      },
    };
    window._satellite.track('revisionparcimmo');
    window._satellite.pageBottom();
    break;
  case 'etape2':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        ...wa_gfr_init.contenu,
        fil_ariane: 'Revision Parc Immobilier::Etape 2 Coordonnées',
      },
      parc_immo: {
        ajout_bat: nbrAjout,
        modification_bat: nbrModif,
        suppression_bat: nbrSuppr,
      },
    };
    window._satellite.track('revisionparcimmo');
    window._satellite.pageBottom();
    break;
  case 'etape3':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        ...wa_gfr_init.contenu,
        fil_ariane: 'Revision Parc Immobilier::Etape 3 Récapitulatif',
      },
    };
    window._satellite.track('revisionparcimmo');
    window._satellite.pageBottom();
    break;
  case 'etape4':
    window.wa_gfr = {
      ...wa_gfr_init,
      contenu: {
        ...wa_gfr_init.contenu,
        fil_ariane: 'Revision Parc Immobilier::Etape 4 Confirmation',
      },
    };
    window._satellite.track('revisionparcimmo');
    window._satellite.pageBottom();
    break;
  default:
    console.log('Cas inconnu de tracker...');
    break;
  }
  console.log('======================');
  console.log(window.wa_gfr);
  console.log('======================');
};

export default tracking;
