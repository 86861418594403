<template>
  <div v-if="Object.keys(declarations).length !== 0" class="storeDatas">
      <div class="storeDataTitle">Vous avez {{ textType }} :</div>
      <div v-for="(declaration, key) in declarations" class="storeDataBlock" :key="key">
        <template v-if="declaration !== null">
          <div>Désignation du bâtiment : <span>{{ declaration.designation }}</span></div>
          <div>Date d'effet : <span>{{ declaration.dateEffet }}</span></div>
          <div>Adresse : <span>{{ declaration.adresse }}</span> - <span>{{ declaration.cpVille }}</span></div>
          <div v-if="type !== 'remove'">Vous êtes : <span>{{ declaration.fonction }}</span></div>
          <div v-if="type !== 'remove'">Type de bâtiment : <span>{{ declaration.typeBatiment }}</span></div>
          <div>Surface du local : <span>{{ declaration.surface }} m²</span></div>
          <div v-if="type !== 'remove'">Siret ou Siren : <span v-if="declaration.siret">{{ declaration.siret }}</span><span v-else>-</span></div>
          <div>Commentaire : <span v-if="declaration.commentaire">{{ declaration.commentaire }}</span><span v-else>-</span></div>
          <div class="storeDataNav" v-if="question.editable">
            <router-link :class='question.classEdit' :to="{ name: 'Questionnaire', params: { section: section, declarationId: key }, query: { ...$route.query, button: 'editDeclaration', type: type } }">
              Modifier
            </router-link>
            <a @click="showModal = true;removeId = key;">Supprimer</a>
          </div>
        </template>
      </div>
      <modal-field v-if="showModal" @close="showModal = false" @confirm="remove()"/>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import { getProp } from '../../utils/SurveyUtils';
import ModalField from './ModalField';
import store from '../../store';

export default {
  props: ['question', 'path', 'responsePath'],
  data: function () {
    return {
      showModal: false,
      removeId: null,
    };
  },
  computed: mapState({
    declarations(state) { return getProp(this.$store.state.survey, 'sections.globals.declarations', this.type); },
    type(state) { return getProp(this.question, 'type'); },
    section(state) { return (this.type === 'add' || this.type === 'edit') ? 'addEdit' : this.type; },
    textType(state) { return (this.type === 'add') ? 'ajouté' : (this.type === 'edit') ? 'modifié' : (this.type === 'remove') ? 'supprimé' : 'ERROR : no type'; },
  }),
  methods: {
    ...mapActions([
      'setValueGlobal',
      'sendBuilding',
    ]),
    remove(state) {
      let declarations = this.declarations;
      delete declarations[this.removeId];
      this.setValueGlobal({ path: `sections.globals.declarations.${this.type}`, value: declarations });
      store.dispatch('sendBuilding', { typeDeclaration: this.type, declarationId: this.removeId, remove: true, survey: this.$store.state.survey });
      this.showModal = false;
      return true;
    },
  },
  components: {
    ModalField,
  },
};
</script>
