import { filter, forEach, get, find, map } from 'lodash';

import { getError } from '../utils/validate';
import optionsCollectivite from '../../data/options-collectivite.json';
import optionsFonctions from '../../data/options-fonctions.json';
import optionsInterlocuteur from '../../data/options-interlocuteur.json';
import optionsTypeBatiment from '../../data/options-type-batiment.json';

const optionsList = {
  'options-collectivite': optionsCollectivite,
  'options-fonctions': optionsFonctions,
  'options-interlocuteur': optionsInterlocuteur,
  'options-type-batiment': optionsTypeBatiment,
};

export const getOptions = options => typeof options === 'string'
  ? optionsList[options]
  : options;

export const getPath = (...paths) => filter(paths, path => path).join('.');

export const getProp = (question, ...paths) => get(question, getPath(...paths));

export const execQuestionDeep = (question = {}, path, responsePath, callback) => {
  const continueneedExecDeep = callback(question, path, responsePath);
  if (continueneedExecDeep !== false) {
    if (question.questions) {
      forEach(question.questions, (subQuestion, nameQuestion) => {
        return execQuestionDeep(
          subQuestion,
          `${path}.questions.${nameQuestion}`,
          responsePath,
          callback,
        );
      });
    }
    if (question.optionPrecisions && question.options) {
      forEach(question.optionPrecisions, (subQuestion, nameQuestion) => {
        forEach(question.options, (option, optionKey) => {
          return execQuestionDeep(
            subQuestion,
            `${path}.optionPrecisions.${nameQuestion}`,
            getPath(responsePath, `responses.${optionKey}`),
            callback,
          );
        });
      });
    }
  }
};

export const execQuestionDeepError = (question = {}, path, responsePath, callback) => {
  const continueneedExecDeep = callback(question, path, responsePath);
  if (continueneedExecDeep === false || continueneedExecDeep === undefined || continueneedExecDeep === null) {
    return true;
  }
  if (continueneedExecDeep !== false) {
    if (question.questions) {
      return !!find(question.questions, (subQuestion, nameQuestion) => {
        return execQuestionDeepError(
          subQuestion,
          `${path}.questions.${nameQuestion}`,
          responsePath,
          callback,
        );
      });
    }
    // non utilisé
    if (question.optionPrecisions && question.options) {
      forEach(question.optionPrecisions, (subQuestion, nameQuestion) => {
        forEach(question.options, (option, optionKey) => {
          return execQuestionDeepError(
            subQuestion,
            `${path}.optionPrecisions.${nameQuestion}`,
            getPath(responsePath, `responses.${optionKey}`),
            callback,
          );
        });
      });
    }
  }
};

export const execSectionDeep = (section = {}, sectionName, callback) => {
  forEach(section.questions, (question, questionName) => {
    execQuestionDeep(question, `sections.${sectionName}.questions.${questionName}`, null, callback);
  });
};

export const execSurveyDeep = (survey = {}, callbackSection, callbackQuestion) => {
  forEach(survey.sections, (section, sectionName) => {
    callbackSection(section, sectionName);
    execSectionDeep(section, sectionName, callbackQuestion);
  });
};

export const isRulesVisible = (visibilityRules, state) => {
  if (visibilityRules === false) return false;
  if (!visibilityRules) return true;
  if (visibilityRules['and']) {
    // Recherche une condition fausse (absence d'erreur === false), si non trouvé => Ok
    // on transform l'object en array
    let rules = Object.keys(visibilityRules['and']).map(function (key) {
      return { [key]: visibilityRules['and'][key] };
    });
    // on parcours toutes les rules (optimisation possible ( arreter le parcours du map
    // si on a isRulesVisible === false))
    const arrayRule = map(rules, rule => {
      return isRulesVisible(rule, state);
    });
    // on obtient un tableau avec toutes les valeurs des rules (si une false)
    // donc find (rule => false) est different d'undefined false sinon true
    return find(arrayRule, rule => (rule === false)) === undefined;
  }
  if (visibilityRules['or']) {
    // Recherche une condition valide (absence d'erreur === true), si trouvé => Ok
    // Not sure....
    return checkRules(visibilityRules['or'], state, true) !== undefined;
  }
  return checkRules(visibilityRules, state, false) === undefined;
};

function checkRules(rules, state, operator) {
  // Recherche parmis toute les règles
  return find(rules, (rule, ruleKey) => {
    // Appel recursif en cas de sous objet or / and
    if (ruleKey === 'or' || ruleKey === 'and') {
      return isRulesVisible(rule, state) === operator;
    }
    // Recherche l'absence d'erreur, retourne l'égalité avec l'opérateur
    return (getError(get(state, `survey.${ruleKey}`), rule) === null) === operator;
  });
}
