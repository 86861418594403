<template>
  <div class="storeDatas">
      <div class="storeDataTitle">Coordonnées client :</div>
      <div class="storeDataBlock">
          <div>Collectivité : <span>{{ section.type_collectivite.response }}</span></div>
          <div>Nom de la Collectivité : <span>{{ section.nom_collectivite.response }}</span></div>
          <div>Nom de l'interlocuteur : <span>{{ section.nom_interlocuteur.response }}</span></div>
          <div>Interlocuteur : <span>{{ section.interlocuteur.response }}</span></div>
          <div>Adresse email : <span>{{ section.email.response }}</span></div>
          <div>Numéro de téléphone : <span>{{ section.telephone.response }}</span></div>
          <div v-if="section.idgrc.response">Identifiant client : <span>{{ section.idgrc.response }}</span></div>
          <div v-if="section.adresseClient.response">Adresse : <span>{{ section.adresseClient.response }}</span> - <span>{{ section.cpVille.response }}</span></div>
      </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';

export default {
  props: ['question', 'path', 'responsePath'],
  data: function () {
    return {
      showModal: false,
      removeId: null,
    };
  },
  computed: mapState({
    section(state) { return this.$store.state.survey.sections.coordonees.questions.infosCoordonees.questions; },
  }),
  methods: {
    ...mapActions([
      'setValueGlobal',
    ]),
  },
};
</script>
