import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import mutations from './mutations';
import actions from './actions';
import state from './state';

const vuexLocal = new VuexPersistence({
  key: 'parc-immobilier',
  storage: window.sessionStorage,
  reducer: (state) => ({
    devisCaisse: state.devisCaisse,
    survey: state.survey,
    surveyWithClientCode: state.surveyWithClientCode,
    isSurveyCompleted: state.isSurveyCompleted,
  }),
});

const store = createStore({
  state,
  mutations,
  actions,
  modules: {},
  plugins: [vuexLocal.plugin]
});

export default store;
