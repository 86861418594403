 <template>
  <main class="container text-center">
    <div class="confirmation-block">
      <i class="icon-confirmation check" />
      <div class="confirm-message">
       Nous accusons réception des déclarations que vous avez effectuées via le formulaire.<br/>
      </div>
      <p>
       Après prise en compte de celles-ci, nous procéderons prochainement à la mise à jour du contrat
       d'assurance de votre collectivité et nous vous adresserons les nouvelles conditions personnelles du contrat dont un exemplaire sera à nous retourner signé.
      </p>
      <p v-if="type_collectivite === 'Association'">
        Pour toute question, nous restons à votre disposition au <a :href="'tel:' + caisse.telephone_privee">{{ caisse.telephone_privee }}</a> ( {{ caisse.horaires_privee }} )<br/>
        Ou par mail à l'adresse <a :href="'mailto:' + caisse.corps_email_privee">{{ caisse.corps_email_privee }}</a>
      </p>
      <p v-else>
        Pour toute question, nous restons à votre disposition au <a :href="'tel:' + caisse.telephone_public">{{ caisse.telephone_public }}</a> ( {{ caisse.horaires_public }} )<br/>
        Ou par mail à l'adresse <a :href="'mailto:' + caisse.corps_email_public">{{ caisse.corps_email_public }}</a>
      </p>
    </div>
    <footer class="confirmation-footer">
      <div class="grid-center-noBottom">
        <router-link class="btn btn-secondary btn-big" :to="{ name: 'Questionnaire', params: { section: 'main', declarationId: null }, query: { ...$route.query, button: 'restart' } }">
          <span>Vous souhaitez faire une demande de révision ?</span>
        </router-link>
      </div>
    </footer>
  </main>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import { forEach } from 'lodash';
import survey from '../../data/survey.json';
import tracking from '../constants/tracking';

export default {
  computed: mapState({
    sectionList(state) {
      return state.survey.sections;
    },
    caisse(state) {
      return state.devisCaisse.caisse;
    },
    type_collectivite(state) {
      return state.survey.sections.coordonees.questions.infosCoordonees.questions.type_collectivite.response;
    },
  }),
  methods: {
    ...mapActions([
      'resetValue',
      'setValueGlobal',
    ]),
  },
  beforeRouteEnter(to, from, next) {
    let tracker = 'etape4';
    console.log('track onRouteChange');
    console.log('>>>>>>>>>>>>>>>>>', tracker);
    tracking(tracker);
    return next();
  },
  beforeRouteLeave(to, from, next) {
    if (to.query.button === 'restart') {
      console.log('reset survey');
      forEach(this.sectionList, (section, key) => {
        this.resetValue({ path: `sections.${key}`, value: survey.sections[key] });
      });
      this.setValueGlobal({ path: 'sections.globals.declarations.add', value: {} });
      this.setValueGlobal({ path: 'sections.globals.declarations.edit', value: {} });
      this.setValueGlobal({ path: 'sections.globals.declarations.remove', value: {} });
      this.setValueGlobal({ path: 'sections.globals.previousSection', value: null });
    }
    return next();
  },
};
</script>
<style lang="scss" scoped>
</style>