<template>
  <div class="modal-mask">
    <div class="modal-wrapper" @click="$emit('close')">
      <div class="modal-container" @click.stop>

        <div class="modal-header">
          Confirmez-vous la suppression de la déclaration ?
        </div>

        <div class="modal-footer">
          <button class="btn" @click="$emit('close')">
            Annuler
          </button>
          <button class="btn btn-primary" @click="$emit('confirm')">
            Confirmer
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['question', 'path', 'responsePath'],
  computed: {},
  emits: ['close', 'confirm'],
};
</script>
