<template>
  <header class="app-header">
    <div class="container">
      <div class="logo"></div>
    </div>
  </header>
</template>
<script>
export default {
};
</script>
<style lang="scss" scoped>
</style>
