import { find, includes, split, upperCase, replace } from 'lodash';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';

// Return boolean, true if the rule is completed
export const validate = {
  required: value => value !== undefined && value !== null && ((value.replace(/ /g, '') !== '' && !Number.isNaN(value)) || value.replace(/ /g, '').length > 0),
  requiredDate: value => {
    return (value !== 'Invalid date' && value !== undefined && value !== null && typeof (value) !== 'object' && ((value.replace(/ /g, '') !== '' && !Number.isNaN(value)) || value.replace(/ /g, '').length > 0));
  },
  empty: value => !validate.required(value),
  email: value => isEmail(value),
  alpha: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ -]+$/i.test(value),
  alphaName: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ - -'/]+$/i.test(value),
  date: value => moment().format('YYYYMMDD-HHmmss'),
  numeric: value => /^[0-9 ]+$/i.test(value),
  maxLength: (value, parameters) => value.length <= parameters,
  special_chars_alpha: value => /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ -/]+$/i.test(value),
  regexp: (value, parameters) => new RegExp(parameters.model).test(value),
  equals: (value, parameters) => value === parameters,
  contains: (value, parameters) => {
    if (Array.isArray(parameters)) {
      for (let i = 0; i < parameters.length; i++) {
        if (includes(value, parameters[i])) {
          return true;
        }
      }
      return false;
    }
    return includes(value, parameters);
  },
  containsIfExists: (value, parameters) => {
    if (value === undefined) {
      return true;
    } else {
      return validate.contains(value, parameters);
    }
  },
  notContains: (value, parameters) => {
    if (Array.isArray(parameters)) {
      for (let i = 0; i < parameters.length; i++) {
        if (includes(value, parameters[i])) {
          return false;
        }
      }
      return true;
    }
    return !includes(value, parameters);
  },
  greater: (value, parameters) => !validate.empty(value) && StringToInt(value) > parameters,
  greaterEquals: (value, parameters) => !validate.empty(value) && StringToInt(value) >= parameters,
  greaterOrEmpty: (value, parameters) => { return validate.empty(value) || StringToInt(value) > parameters; },
  greaterEqualsOrEmpty: (value, parameters) => validate.empty(value) || StringToInt(value) >= parameters,
  lower: (value, parameters) => { return !validate.empty(value) && StringToInt(value) < parameters; },
  lowerEquals: (value, parameters) => !validate.empty(value) && StringToInt(value) <= parameters,
  lowerOrEmpty: (value, parameters) => validate.empty(value) || StringToInt(value) < parameters,
  lowerEqualsOrEmpty: (value, parameters) => validate.empty(value) || StringToInt(value) <= parameters,
  accept: (value) => { return (!value) ? true : find(value.extensions, (ext) => upperCase(split(value.file.name, '.')[1]) === upperCase(ext)) !== undefined; },
  weight: (value) => { return (value && value.file) ? value.file.size < 2000000 : true; },
};

export function StringToInt(value) {
  return (isNaN(value)) ? (isNaN(replace(value, / /g, '')) ? 0 : replace(value, / /g, '')) : value;
}

export function getError(value, rules) {
  const ruleError = find(rules, (params, rule) => {
    if (!validate[rule]) {
      console.error('Unknow validation funtion : ', rule, params);
      return false; // Unkown function are not valid
    }
    return !validate[rule](value, params);
  });
  return ruleError
    ? ruleError.message || ruleError
    : null;
}
