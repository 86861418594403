import { createRouter, createWebHistory } from 'vue-router';

import SurveyForm from './SurveyForm';
import NotFoundComponent from './NotFoundComponent';
import SurveyCompleted from './SurveyCompleted';

import survey from '../../data/survey.json';

const routes = [
  {
    name: 'notFound',
    path: '/:pathMatch(.*)*',
    component: NotFoundComponent,
    meta: {
      title: '404 Not Found',
    },
  },
  {
    name: 'Accueil',
    path: '/',
    redirect: {
      name: 'Questionnaire',
      params: { section: Object.keys(survey.sections)[1] },
    },
  },
  {
    name: 'Complet',
    path: '/completer',
    component: SurveyCompleted,
    meta: {
      title: 'Complet',
      'keepAlive': false,
    },
  },
  {
    name: 'Questionnaire',
    props: (route) => ({ query: route.query.q }),
    path: '/:section/:declarationId?',
    component: SurveyForm,
    meta: {
      title: 'Questionnaire',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  base: process.env.BASE_URL,
});

router.afterEach((to) => {
  if (to.meta.title !== undefined) {
    document.title = `${to.meta.title} - Révision Parc Immobilier Webapp`;
  }
});

export default router;
