 <template>
  <main class="container">
    <div class="question-title" v-if="section.labelAdd && $route.params.declarationId === 'add'">{{ section.labelAdd }}</div>
    <div class="question-title" v-if="section.labelEdit && $route.params.declarationId === 'edit'">{{ section.labelEdit }}</div>
    <question-layout
      v-for="(question, questionKey) in section.questions"
      :key="questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]"
      :questionName="questionKey.indexOf('questions.') == -1 ? questionKey : questionKey.split('questions.')[1]"
      :question="questionKey.indexOf('questions.') == -1 ? question : Object.assign({}, questions[`${questionKey.split('questions.')[1]}`], question)"
      :path="
      questionKey.indexOf('questions.') == -1
        ? (`sections.${$route.params.section}.questions.${questionKey}`)
        : `sections.${$route.params.section}.questions.${questionKey.split('questions.')[1]}`
      "
      :responsePath="question.responsePath"
    />
    <footer>
      <div class="row">
        <div class="col-auto mr-auto">
          <router-link
            v-if="previousSection"
            :to="{ name: 'Questionnaire', params: { section: previousSection, declarationId: declarationId }, query: { ...$route.query, button: 'previous' } }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small">
              <span data-qa="precedent">PRÉCÉDENT</span>
            </button>
          </router-link>
        </div>
        <div class="col-auto" v-if="nextSection && (haveDeclarations || $route.params.section !== 'main')">
          <router-link
            :to="{ name: 'Questionnaire', params: { section: nextSection, declarationId: declarationId}, query: { ...$route.query, button: 'next' } }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small" :class="{ 'opacity': !isValide }">
              <span data-qa="suivant" >{{ section.nextButton ? section.nextButton : 'SUIVANT' }}</span>
            </button>
          </router-link>
        </div>
        <div class="col-auto" v-if="!nextSection">
          <router-link
            :to="{ name: 'Complet', query: $route.query }"
            custom v-slot="{ navigate }"
          >
            <button @click="navigate" @keypress.enter="navigate" class="btn btn-secondary btn-small" :class="{ 'opacity': !isValide }">
              <span data-qa="suivant" >VALIDER</span>
            </button>
          </router-link>
        </div>
      </div>
    </footer>
    <div class="mentions-legales">
    </div>
  </main>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { keys, find, get, map, filter, forEach, random } from 'lodash';
import { isRulesVisible, getProp, execQuestionDeepError } from '../utils/SurveyUtils';
import survey from '../../data/survey.json';
import store from '../store';
import { getError } from '../utils/validate';
import tracking from '../constants/tracking';

export default {
  computed: mapState({
    sectionList(state) {
      return state.survey.sections;
    },
    declarationId(state) {
      return this.$route.params.declarationId;
    },
    sectionNameList(state) {
      return filter(keys(state.survey.sections), name => (name !== 'globals'));
    },
    section(state) {
      return state.survey.sections[this.$route.params.section];
    },
    previousSection(state) {
      // récupère la section précédente
      let previousSections = get(this.$store.state.survey, 'sections.globals.previousSection');

      if (previousSections === null) {
        return null;
      }

      let i = 1;
      while (i <= previousSections.length) {
        // si la section précédente est égal à la section courante on regarde celle d'avant
        if (previousSections[previousSections.length - i] !== this.$route.params.section) {
          return previousSections[previousSections.length - i];
        }
        i++;
      }
      return null;
    },
    visibilityRulesBtNext(state) {
      return state.survey.sections[this.$route.params.section].visibilityRulesBtNext;
    },
    nextSection(state) {
      // const sections = keys(state.survey.sections);
      // si les visibility rules de la section ne sont pas respectées, on regarde la section d'après ...
      let section = null;
      forEach(this.section.next, function (nextSection, name) {
        if (isRulesVisible(nextSection.visibilityRules, state)) {
          section = name;
        }
      });
      return section;
    },
    questions(state) {
      return state.survey.questions;
    },
    isValide(state) {
      const sectionName = this.$route.params.section;
      // callback -> return true if we need to search error in survey or false if we have get error
      let callback = (q, p, rp) => {
        const isVisible = isRulesVisible(q.visibilityRules, state);
        if (isVisible) {
          const error = getError(getProp(q, rp, 'response'), q.rules);
          return !error;
        }
        return true;
      };
      let section = get(state, `survey.sections.${sectionName}`);
      // regarder les erreurs sur les questions de la section
      const arrayError = map(section.questions, (question, questionName) => {
        return !execQuestionDeepError(question, `sections.${sectionName}.questions.${questionName}`, question.responsePath, callback);
      });
      return (find(arrayError, bool => (bool === false)) === undefined);
    },
    haveDeclarations(state) {
      let declarations = getProp(this.$store.state.survey, 'sections.globals.declarations');
      return find(declarations, element => (element !== undefined && element !== null && typeof element === 'object' && Object.keys(element).length !== 0)) !== undefined;
    },
  }),
  methods: {
    ...mapActions([
      'validateSection',
      'sendSurvey',
      'sendBuilding',
      'resetValue',
      'setValidateSection',
      'toggleBtNext',
      'addPreviousSection',
      'popPreviousSection',
      'setValueGlobal',
      'setValue',
    ]),
    changeValueValidateSection(path, value) {
      this.setValidateSection({ path, value });
    },
    onRouteChange(to, from, next) {
      // validation section
      this.validateSection(from.params.section)
      .then((props) => {
        if (this.section.isValidated === true) {
          store.dispatch('sendSurvey', { query: from.query, survey: this.$store.state.survey, caisse: this.$store.state.devisCaisse, completed: !this.nextSection });
          window.scroll(0, 0);
        }
        next(this.section.isValidated === true);
        window.scroll(0, 0);
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.isSurveyCompleted) {
      return next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    // gestion tracker
    // debugger;
    let tracker;
    const button = to.query.button;
    if (this.$store.state.survey.sections[to.params.section].tracking) {
      tracker = this.$store.state.survey.sections[to.params.section].tracking;
      let nbrAjout, nbrModif, nbrSuppr;
      if (tracker.track === 'etape2') {
        nbrAjout = Object.keys(this.$store.state.survey.sections.globals.declarations.add).length;
        nbrModif = Object.keys(this.$store.state.survey.sections.globals.declarations.edit).length;
        nbrSuppr = Object.keys(this.$store.state.survey.sections.globals.declarations.remove).length;
      }
      console.log('track onRouteChange');
      console.log('>>>>>>>>>>>>>>>>>', tracker.track);
      tracking((tracker.track === 'etape1.2 - Ajout/Modif') ? (to.params.declarationId === 'edit') ? 'etape1.2 - Modif' : 'etape1.2 - Ajout' : tracker.track, nbrAjout, nbrModif, nbrSuppr);
    }

    // Gestion de la création/modification d'une déclaration
    if ((from.params.section === 'addEdit' || from.params.section === 'remove') && this.isValide && button === 'next') {
      // création d'une déclaration
      let form = this.section.questions.infosBatiment.questions;
      let declaration = {
        designation: getProp(form.designation, form.designation.responsePath, 'response'),
        dateEffet: getProp(form.dateEffet, form.dateEffet.responsePath, 'response'),
        adresse: getProp(form.adresse, form.adresse.responsePath, 'response'),
        fonction: (form.fonction) ? getProp(form.fonction, form.fonction.responsePath, 'response') : '',
        siret: (form.siret) ? getProp(form.siret, form.siret.responsePath, 'response') : '',
        surface: getProp(form.surface, form.surface.responsePath, 'response'),
        typeBatiment: (form.typeBatiment) ? getProp(form.typeBatiment, form.typeBatiment.responsePath, 'response') : '',
        commentaire: getProp(form.commentaire, form.commentaire.responsePath, 'response'),
        cpVille: getProp(form.cpVille, form.cpVille.responsePath, 'response'),
        batiment_type: (this.declarationId === 'add' || this.declarationId === 'edit' || this.declarationId === 'remove') ? this.declarationId : from.params.type,
      };

      // ajout ou écrase dans globals
      if (this.declarationId === 'add' || this.declarationId === 'edit' || this.declarationId === 'remove') {
        // dans ce cas création d'une déclaration ==> this.declarationId contient le type
        let id = random(0, Number.MAX_SAFE_INTEGER);
        this.setValueGlobal({ path: `sections.globals.declarations.${this.declarationId}.${id}`, value: declaration });
        store.dispatch('sendBuilding', { typeDeclaration: this.declarationId, declarationId: id, create: true, survey: this.$store.state.survey });
      } else {
        // dans ce cas édition d'une déclaration ==> this.declarationId contient l'id
        this.setValueGlobal({ path: `sections.globals.declarations.${from.params.type}.${this.declarationId}`, value: declaration });
        store.dispatch('sendBuilding', { typeDeclaration: from.params.type, declarationId: this.declarationId, edit: true, survey: this.$store.state.survey });
      }

      // on dépile le dernière element de l'historique des précédentes versions car on retourne sur le
      this.popPreviousSection(from.params.section);
      next();
    } else if (button === 'next' || button === 'validate' || button === 'editDeclaration') {
      // si on appui sur suivant
      // on ajoute la section courante à l'historique des sections
      if (from.params.section !== to.params.section) {
        this.addPreviousSection(from.params.section);
      }
      this.onRouteChange(to, from, next);
    }

    // avant d'arriver sur le formulaire de déclarations, on reset le formulaire de déclaration
    if (to.params.section === 'addEdit' || to.params.section === 'remove') {
      this.resetValue({ path: `sections.${to.params.section}`, value: survey.sections[to.params.section] });
    }

    // en arrivant sur l'édition d'une déclaration, on rempli le formulaire
    if (button === 'editDeclaration') {
      let editForm = get(store.state, `survey.sections.globals.declarations.${to.params.type}.${to.params.declarationId}`);
      let setValue = this.setValue;
      forEach(editForm, function (prop, key) {
        setValue({ path: `sections.${(to.params.type === 'add' || to.params.type === 'edit') ? 'addEdit' : to.params.type}.questions.infosBatiment.questions.${key}`, responsePath: get(store.state, `survey.sections.${to.params.type}.questions.infosBatiment.questions.${key}.responsePath`), value: prop });
      });
    }

    if (button === 'previous') {
      // si on appui sur précédent on reset le formulaire incomplet
      // this.resetValue({ path: `sections.${this.$route.params.section}`, value: survey.sections[this.$route.params.section] });
      // et dépile le dernière element de l'historique des précédentes versions
      this.popPreviousSection(from.params.section);

      // console.log('value of section to clean : ', this.$route.params.section);
      // this.changeValueValidateSection(`sections.${this.previousSection}`, false);
      next();
    } else if (button === 'linkField') {
      // si on arrive sur le formulaire de déclaration via un "LinkField"
      // on ajoute la section courante à l'historique des sections
      if (from.params.section !== to.params.section) {
        this.addPreviousSection(from.params.section);
      }
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.onRouteChange(to, from, next);
  },
};
</script>
<style lang="scss" scoped>
</style>
