<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <VueDatePicker 
      v-model="date"
      model-type="dd/MM/yyyy"
      :enable-time-picker="false"
      auto-apply
      @update:model-value="e => setValueDate({ path, responsePath, value: e })"
      @date-update="e => checkError({ path, responsePath, value: e })"
      input-class-name="form-control"
    />
    <label v-if="question.labelInput">
      {{question.labelInput}}
      <!-- Info bulle -->
      <!-- <tooltip-element :question="question" /> -->
    </label>

  </div>
</template>
<script>
import { mapActions } from 'vuex';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { getProp } from '../../utils/SurveyUtils';

export default {
  props: ['question', 'path', 'responsePath'],
  data: function () {
    return {
      date: null,
    };
  },
  computed: {
    response() {
      return getProp(this.question, this.responsePath, 'responseFull');
    },
    filled() {
      return this.response && this.response.length > 0 && 'filled';
    },
  },
  methods: {
    ...mapActions([
      'setValueDate',
      'checkError',
    ]),
  },
  components: {
    VueDatePicker
  },
  mounted() {
    let stringDate = getProp(this.question, this.responsePath, 'response');
    if (stringDate !== null && stringDate !== undefined && stringDate.split('/')[2] !== undefined) {
      this.date = new Date(stringDate.split('/')[2], stringDate.split('/')[1], stringDate.split('/')[0]);
    }
  },
};
</script>
