<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <router-link
      :class='question.type'
      :to="{
        name: 'Questionnaire',
        params: { section: question.sectionLink, declarationId: question.sectionParam },
        query: { ...$route.query, button: 'linkField' }
        }"
      >
      <span>{{firstBuilding ? question.btnText : question.btnResetText}}</span>
      <i />
    </router-link>
  </div>
</template>
<script>

import { isEmpty } from 'lodash';
import { getProp } from '../../utils/SurveyUtils';

export default {
  props: ['question', 'path', 'responsePath'],
  computed: {
    type(state) { return getProp(this.question, 'type'); },
    firstBuilding(state) { return isEmpty(this.$store.state.survey.sections.globals.declarations.add) && isEmpty(this.$store.state.survey.sections.globals.declarations.edit) && isEmpty(this.$store.state.survey.sections.globals.declarations.remove); },
  },
};
</script>
