<template>
  <div
    v-if="isVisible"
    :class="[isChildren ? 'col' : 'field', question.questionClass, questionName, question.questionType]"
  >
    <label v-if="question.label" :class="['question-title', question.questionTitleClass]">{{question.label}}</label>

    <div class="question-wrapper">
      <div
        :class="['row', question.questionRowClass]">

        <!-- Render error message -->
        <div
          v-if="error"
          class="col-12 error"
          >
          <div
            class="error-message"
          >
            {{error}}
          </div>
        </div>

        <!-- Render question field following questionType -->
        <input-field v-if="question.questionType === 'input'" :question="question" :path="path" :responsePath="responsePath" />
        <cleave-field v-if="question.questionType === 'cleave'" :question="question" :path="path" :responsePath="responsePath" />
        <radio-field v-else-if="question.questionType === 'radio'" :question="question" :path="path" :responsePath="responsePath" :questionName="questionName" />
        <checkbox-field v-else-if="question.questionType === 'checkbox'" :question="question" :path="path" :responsePath="responsePath" />
        <select-field v-else-if="question.questionType === 'select'" :question="question" :path="path" :responsePath="responsePath" />
        <date-month-year-field v-else-if="question.questionType === 'date-month-year'" :question="question" :path="path" :responsePath="responsePath" />
        <calendar-field v-else-if="question.questionType === 'calendar'" :question="question" :path="path" :responsePath="responsePath" />
        <download-field v-else-if="question.questionType === 'download'" :question="question" :path="path" :responsePath="responsePath" />
        <text-field v-else-if="question.questionType === 'text'" :question="question" :path="path" :responsePath="responsePath" />
        <textConfirm-field v-else-if="question.questionType === 'textConfirm'" :question="question" :path="path" :responsePath="responsePath" />
        <declaration-field v-else-if="question.questionType === 'declaration'" :question="question" :path="path" :responsePath="responsePath" />
        <download-button-field v-else-if="question.questionType === 'downloadButton'" :question="question" :path="path" :responsePath="responsePath" />
        <link-field v-else-if="question.questionType === 'link'" :question="question" :path="path" :responsePath="responsePath" />
        <text-area-field v-else-if="question.questionType === 'textarea'" :question="question" :path="path" :responsePath="responsePath" />
        <show-coordonnees-field v-else-if="question.questionType === 'showCoordonnees'" :question="question" :path="path" :responsePath="responsePath" />

        <!-- Render child questions if exists -->
        <question-layout
          v-for="(subQuestion, key) in question.questions"
          :key="key"
          :questionName="key"
          :question="subQuestion"
          :path="`${path}.questions.${key}`"
          :responsePath="responsePath"
          :isChildren="true"
        />

        <!-- Render reset button if resetLabel exist -->
        <div
          class="col column"
          v-if="question.resetLabel && question.resetLabel.length >= 1"
        >
          <button
            type="button"
            :class="['btn btn-primary fullHeight', { selected: isResetSelected() }]"
            @click="resetQuestion({ path, responsePath })"
          >
            <span>{{question.resetLabel}}</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { find, get } from 'lodash';

import { getProp, isRulesVisible } from '../utils/SurveyUtils';
import survey from '../../data/survey.json';

import InputField from './fields/InputField';
import CleaveField from './fields/CleaveField';
import RadioField from './fields/RadioField';
import CheckboxField from './fields/CheckboxField';
import SelectField from './fields/SelectField';
import DateMonthYearField from './fields/DateMonthYearField';
import CalendarField from './fields/CalendarField';
import DownloadField from './fields/DownloadField';
import TextField from './fields/TextField';
import TextConfirmField from './fields/TextConfirmField';
import DownloadButtonField from './fields/DownloadButtonField';
import DeclarationField from './fields/DeclarationField';
import LinkField from './fields/LinkField';
import TextAreaField from './fields/TextAreaField';
import showCoordonneesField from './fields/ShowCoordonneesField';

export default {
  props: ['questionName', 'question', 'path', 'responsePath', 'isChildren'],
  computed: {
    ...mapState({
      isVisible(state) {
        const visible = isRulesVisible(this.question.visibilityRules, state);
        const hidden = this.question.hidden;
        if (visible === false && this.question.response && !hidden) {
          // if visible false, and this question have a response,
          // we set the value with the initial state (survey.json)
          this.resetValue({ path: this.path, value: get(survey, this.path) });
        }
        return visible;
      },
    }),

    error() {
      return getProp(this.question, this.responsePath, 'error');
    },
  },
  methods: {
    ...mapActions([
      'resetQuestion',
      'toggleBtNext',
      'resetValue',
    ]),
    isResetSelected(question = this.question) {
      return getProp(question, this.responsePath, 'response') === null && find(question.questions, q => !this.isResetSelected(q)) === undefined;
    },
  },
  components: {
    InputField,
    CleaveField,
    RadioField,
    CheckboxField,
    SelectField,
    DateMonthYearField,
    CalendarField,
    DownloadField,
    TextField,
    TextConfirmField,
    DownloadButtonField,
    DeclarationField,
    LinkField,
    TextAreaField,
    showCoordonneesField,
  },
};
</script>
<style lang="scss">
</style>
