<template>
</template>
<script>
import { mapState } from 'vuex';

export default {
  computed: mapState({
    title: state => state.survey.title,
    isSurveyCompleted: state => state.isSurveyCompleted,
    sections: state => state.survey.sections,
  }),
};
</script>
<style lang="scss" scoped>
</style>
