<template>
  <div :class="`${question.answerColClass || 'col'} field`">
    <cleave
      :placeholder="question.placeholder"
      :data-qa="question.serverPath"
      :name="question.labelInput"
      :class="['form-control', filled, question.fieldClass]"
      :options="question.cleaveOptions"
      v-model="response"
      @blur="e => setValue({ path, responsePath, value: response })"
      @input="e => {checkError({ path, responsePath, value: response }) && setValue({ path, responsePath, value: response })}"
    />
    <label v-if="question.labelInput">{{question.labelInput}}</label>
    <label v-if="question.labelSuffix">{{question.labelSuffix}}</label>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import Cleave from 'vue-cleave-component';
import { getProp } from '../../utils/SurveyUtils';

export default {
  props: ['question', 'path', 'responsePath'],
  data: function () {
    return {
      response: null,
    };
  },
  computed: {
    filled() {
      return this.response && this.response.length > 0 && 'filled';
    },
  },
  methods: {
    ...mapActions([
      'setValue',
      'checkError',
    ]),
  },
  components: {
    Cleave,
  },
  mounted: function () {
    this.response = getProp(this.question, this.responsePath, 'response');
  },
};
</script>
