import { createApp } from 'vue';
import VueSelect from 'vue-select';
import DOMPurify from 'dompurify';
import tracking from './constants/tracking';
import './assets/styles/app.scss';

import router from './router';
import MainLayout from './components/layouts/MainLayout';
import store from './store';

// Import global componenents
import QuestionLayout from './components/QuestionLayout';
import TooltipElement from './components/fields/TooltipElement';

/* eslint-disable no-new */
const app = createApp({
  setup() {
    return {};
  },
  template: '<main-layout/>',
  components: {
    MainLayout,
  },
  created() {
    const currentSession = sessionStorage.getItem('parc-immobilier');
    let current = null;
    if (currentSession !== '[object Object]') {
      current = JSON.parse(currentSession);
    }
    // Initial sessionstorage
    if (!current) {
      sessionStorage.setItem('parc-immobilier', current);
      console.log('init tracking');
      tracking('init');
    }
    let codeClient = null;
    if (current) {
      codeClient = current.survey.sections.main.questions.exportId.response;
    }
    store.dispatch('fetchSurvey', codeClient);
  },
  watch: {
    '$store.state.isSurveyCompleted': function (isSurveyCompleted) {
      if (isSurveyCompleted === true) {
        router.push({ name: 'Complet' });
      }
    },
  },
});

app.use(router);
app.use(store);

app.mount('#root');

// QuestionLayout must be global to be call by their children...
// (Why can't use module import in the children, don't know....)
app.component('question-layout', QuestionLayout);
app.component('tooltip-element', TooltipElement);
app.component('v-select', VueSelect);

app.directive('sane-html', (el, binding) => {
  el.innerHTML = DOMPurify.sanitize(binding.value);
});

if (__DEV__) {
  // Remove productionTip
  app.config.productionTip = false;
}

export default app;
